import ArrSVG from '@public/icons/btn-arr.svg';
import classNames from 'classnames';
import { forwardRef, useCallback, useEffect, useState } from 'react';

interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
    numSlides: number;
    currentRealIndex: number;
    lastRealIndex: number;
    prevElClass: string;
    nextElClass: string;
    wrapperClass: string;
    onPrevClick?: () => void;
    onNextClick?: () => void;
}

const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
    (
        {
            currentRealIndex,
            lastRealIndex,
            prevElClass,
            nextElClass,
            wrapperClass,
            numSlides,
            onPrevClick,
            onNextClick,
            ...props
        },
        ref,
    ) => {
        const numDots = 9;
        const initialPositions = Array.from({ length: numDots }, (_, i) => i - (numDots - 1) / 2);
        const [positions, setPositions] = useState(initialPositions);

        const shift = useCallback((direction: number) => {
            setPositions((prevPositions) =>
                prevPositions.map((pos) => {
                    let newPos = pos - direction;
                    if (newPos > (numDots - 1) / 2) {
                        return newPos - numDots;
                    } else if (newPos < -(numDots - 1) / 2) {
                        return newPos + numDots;
                    }
                    return newPos;
                }),
            );
        }, []);

        useEffect(() => {
            let direction = 0;
            if (currentRealIndex === 0 && lastRealIndex === numSlides - 1) {
                direction = 1;
            } else if (currentRealIndex === numSlides - 1 && lastRealIndex === 0) {
                direction = -1;
            } else if (currentRealIndex > lastRealIndex) {
                direction = 1;
            } else if (currentRealIndex < lastRealIndex) {
                direction = -1;
            }

            shift(direction);
        }, [currentRealIndex, lastRealIndex, numSlides, shift]);

        const calculateScale = (position: number) => {
            if (Math.abs(position) <= 4) {
                return 1 - 0.15 * Math.abs(position);
            }
            return 0;
        };

        const calculateOpacity = (position: number) => {
            if (position === 0) return 1;
            if (Math.abs(position) === 4) return 0;
            return 0.6;
        };

        return (
            <div {...props} ref={ref} className={classNames(wrapperClass, props.className)}>
                <button className={prevElClass} aria-label="Пролистать назад" onClick={onPrevClick}>
                    <ArrSVG />
                </button>
                <div className="dots">
                    {positions.map((pos, index) => (
                        <span
                            key={index}
                            className="dot"
                            style={{
                                transform: `translateX(${pos * 43}px) translateY(-50%) scale(${calculateScale(pos)})`,
                                opacity: calculateOpacity(pos),
                            }}
                        />
                    ))}
                </div>
                <button className={nextElClass} aria-label="Пролистать вперёд" onClick={onNextClick}>
                    <ArrSVG />
                </button>
            </div>
        );
    },
);

Pagination.displayName = 'Pagination';

export default Pagination;

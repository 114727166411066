import CloseSVG from '@public/icons/close-btn.svg';
import { Partner } from '@/entities/partner';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import Image from '@/shared/components/shared/Image';
import Link from '@/shared/components/shared/Link';
import Popup from '@/shared/components/shared/Popup';

interface Props {
    partner: Partner | null;
}

export const ORGANIZE_DETAIL_POPUP = 'organize-detail-popup';

function OrganizeDetailPopup({ partner }: Props) {
    const { closePopup } = useSetOpenedPopupsState();

    return (
        <Popup
            name={ORGANIZE_DETAIL_POPUP}
            data-popup-preset="slide-right"
            className="organize-detail-popup"
            overlay={true}
        >
            <button
                className="menu-popup-close-btn"
                onClick={() => {
                    closePopup(ORGANIZE_DETAIL_POPUP);
                }}
                aria-label="Закрыть"
            >
                <CloseSVG />
            </button>
            <div className="organize-detail-popup-top">
                <div className="organize-detail-popup-logo-block">
                    {partner?.logo && (
                        <div className="organize-detail-popup-logo">
                            <Image
                                src={partner.logo.src}
                                width={partner.logo.width}
                                height={partner.logo.height}
                                alt={partner.logo.alt || ''}
                            />
                        </div>
                    )}
                </div>
                {partner?.title && <div className="organize-detail-popup-logo-name text-s">{partner.title}</div>}

                {partner?.href && (
                    <Link
                        href={partner.href}
                        target="_blank"
                        rel="noreferrer"
                        className="organize-detail-popup-link text-xs"
                    >
                        {partner.href.replace(/^https?:\/\//, '')}
                    </Link>
                )}
            </div>
            {partner?.description && (
                <div
                    className="organize-detail-popup-bottom text-s"
                    dangerouslySetInnerHTML={{ __html: partner.description }}
                ></div>
            )}
        </Popup>
    );
}

export default OrganizeDetailPopup;

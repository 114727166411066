import { useEffect, useState } from 'react';

export const useOverflowing = (ref: React.RefObject<HTMLDivElement>) => {
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const onResize = () => {
            if (ref.current) {
                setIsOverflowing(ref.current.scrollWidth > ref.current.offsetWidth);
            }
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [ref]);

    return isOverflowing;
};

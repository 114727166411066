import { toFormData } from '../lib/utils/forms';
import { axiosAPI } from './axios-instance';
import { ApiResponse } from './lib/types';

export type ApiBecomePartnerRequest = {
    name: string;
    lastname: string;
    inn: string;
    email: string;
    phone: string;
    message?: string;
    //для АМО
    url?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
};

export type ApiBecomePartnerResponse = ApiResponse<{
    message: string;
}>;

export type AppBecomePartnerResponse = ApiResponse<{
    message: string;
}>;

const transformApiResponse = (response: ApiBecomePartnerResponse): AppBecomePartnerResponse => {
    return response;
};

export const postBecomePartner = (body: ApiBecomePartnerRequest): Promise<AppBecomePartnerResponse> =>
    axiosAPI
        .post<ApiBecomePartnerResponse>('/v1/feedback/partner', toFormData(body))
        .then((res) => transformApiResponse(res.data));

import CloseSVG from '@public/icons/close-btn.svg';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { AnimatePresence, m as motion } from 'framer-motion';
import { FocusEventHandler, useRef, useState } from 'react';
import { AnyObject, StringSchema, addMethod, boolean, object, string } from 'yup';
import { postBecomePartner } from '@/shared/api/post-become-partner';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import Button from '@/shared/components/shared/Button';
import Checkbox from '@/shared/components/shared/Checkbox';
import Input from '@/shared/components/shared/Input';
import Popup from '@/shared/components/shared/Popup';
import Typografed from '@/shared/components/shared/Typografed';
import { useGetCurrentUrl } from '@/shared/hooks/use-get-url';
import { useGetUtmParams } from '@/shared/hooks/use-utm/use-get-utm';
import { FeedbackResponse } from '@/shared/lib/types';
import { tp } from '@/shared/lib/typograf';
import { formatPhoneHref } from '@/shared/lib/utils/strings';
import { isPhone } from '@/shared/lib/utils/validation/phone';

export const ORGANIZE_FORM_POPUP = 'organize-form-popup';

addMethod(string, 'customPhone', function (errorMessage) {
    return this.test(`test-custom-phone`, errorMessage, function (value) {
        return typeof value === 'string' ? isPhone(value) : false;
    });
});

function OrganizeFormPopup({ data = {} }) {
    const getUtmParams = useGetUtmParams();
    const currentUrl = useGetCurrentUrl();
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [response, setResponse] = useState<FeedbackResponse | null>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const { closePopup } = useSetOpenedPopupsState();

    const schema = object({
        lastname: string().required('Поле обязательно к заполнению'),
        name: string().required('Поле обязательно к заполнению'),
        inn: string().required('Поле обязательно к заполнению'),
        email: string()
            .required('Поле обязательно к заполнению')
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Введите корректный адрес эл. почты'),
        phone: (
            string().required('Поле обязательно к заполнению') as StringSchema<string, AnyObject, undefined, ''> & {
                customPhone: any;
            }
        ).customPhone('Номер телефона введён некорректно'),
        agreementChecked: boolean().oneOf([true]),
    });

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        setFieldTouched,
        setValues,
        setTouched,
        initialTouched,
        initialValues,
    } = useFormik({
        initialValues: {
            ...data,
            check_val: '1',
            lastname: '',
            name: '',
            inn: '',
            email: '',
            phone: '',
            agreementChecked: false,
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            clearTimeout(timeoutRef.current);
            setResponse(null);

            try {
                const data = await postBecomePartner({
                    ...values,
                    phone: formatPhoneHref(values.phone).replace('+', ''),
                    url: currentUrl.length > 0 ? currentUrl : '',
                    ...Object.fromEntries(Object.entries(getUtmParams).filter(([_, value]) => value)),
                });

                setResponse(data);

                if (data.status === 'success') {
                    setValues(initialValues);
                    setTouched(initialTouched);
                } else {
                    setResponse({
                        status: 'error',
                        message: data.errors?.flat().join('<br>') ?? 'Что-то пошло не так',
                    });
                }
            } catch (err) {
                if (err instanceof AxiosError) {
                    setResponse({
                        status: 'error',
                        message: err.response?.data.errors?.flat().join('<br>') ?? 'Что-то пошло не так',
                    });
                } else {
                    throw err;
                }
            } finally {
                timeoutRef.current = setTimeout(() => {
                    setResponse(null);
                }, 5000);
            }
        },
    });

    const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
        setFieldTouched(event.target.name, false);
    };

    return (
        <Popup
            name={ORGANIZE_FORM_POPUP}
            data-popup-preset="slide-right"
            className="organize-form-popup"
            overlay={true}
        >
            <button className="menu-popup-close-btn" onClick={() => closePopup(ORGANIZE_FORM_POPUP)}>
                <CloseSVG />
            </button>
            <h2 className="organize-form-popup-title text-l">Стать партнером</h2>
            <form ref={formRef} className="organize-form-popup-form" noValidate onSubmit={handleSubmit}>
                <div className="organize-input-block text-s">
                    <div className="organize-input-name text-s">Фамилия</div>
                    <Input
                        label=""
                        type="text"
                        name="lastname"
                        placeholder="Иванов"
                        className="organize-form-popup-input"
                        id="organize-form-surname"
                        value={values.lastname}
                        onInput={handleChange}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onBlur={handleBlur}
                        message={touched.lastname ? errors.lastname : ''}
                        valid={!(touched.lastname && errors.lastname)}
                    />
                </div>
                <div className="organize-input-block text-s">
                    <div className="organize-input-name text-s">Имя</div>
                    <Input
                        label=""
                        type="text"
                        name="name"
                        className="organize-form-popup-input"
                        id="organize-form-name"
                        placeholder="Иван"
                        value={values.name}
                        onInput={handleChange}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onBlur={handleBlur}
                        message={touched.name ? errors.name : ''}
                        valid={!(touched.name && errors.name)}
                    />
                </div>

                <div className="organize-input-block text-s">
                    <div className="organize-input-name text-s">Название компании / ИНН</div>
                    <Input
                        label=""
                        type="text"
                        name="inn"
                        id="organize-form-company"
                        className="organize-form-popup-input"
                        placeholder="TNF / 7743013902"
                        value={values.inn}
                        onInput={handleChange}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onBlur={handleBlur}
                        message={touched.inn ? errors.inn : ''}
                        valid={!(touched.inn && errors.inn)}
                    />
                </div>

                <div className="organize-input-block text-s">
                    <div className="organize-input-name text-s">Email</div>
                    <Input
                        type="email"
                        name="email"
                        className="organize-form-popup-input"
                        placeholder={'Введите свой e-mail'}
                        required
                        label=""
                        disabled={isSubmitting}
                        id="organize-form-email"
                        value={values.email}
                        onInput={handleChange}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onBlur={handleBlur}
                        message={touched.email ? errors.email : ''}
                        valid={!(!!touched.email && !!errors.email)}
                    />
                </div>

                <div className="organize-input-block text-s">
                    <div className="organize-input-name text-s">Телефон</div>
                    <Input
                        label=""
                        type="text"
                        className="organize-form-popup-input"
                        name="phone"
                        placeholder="Телефон"
                        id="organize-form-phone"
                        value={values.phone}
                        onInput={handleChange}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onBlur={handleBlur}
                        message={touched.phone ? errors.phone : ''}
                        valid={!(touched.phone && errors.phone)}
                    />
                </div>

                <div className="organize-form-popup-privacy">
                    <div className="form-checkbox-wrapper">
                        <Checkbox
                            className="agreement-checkbox"
                            name="agreementChecked"
                            checked={values.agreementChecked}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            valid={!errors.agreementChecked}
                        >
                            Соглашаюсь с условиями{' '}
                            <a
                                className="form-checkbox-link"
                                href="/privacy/"
                                target="_blank"
                                rel="noreferrer nofollow"
                            >
                                политики конфиденциальности
                            </a>{' '}
                            и принимаю{' '}
                            <a
                                className="form-checkbox-link"
                                href="/privacy/"
                                target="_blank"
                                rel="noreferrer nofollow"
                            >
                                договор-оферты
                            </a>
                            .
                        </Checkbox>
                        <p className="privacy-checkbox"></p>
                    </div>
                    <Button
                        type="submit"
                        variant="outline-dark"
                        disabled={isSubmitting}
                        className="organize-form-popup-submit"
                    >
                        Отправить
                    </Button>
                </div>
                <AnimatePresence>
                    {response && (
                        <motion.div
                            className="organize-detail-popup-response"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            {response.status === 'success' ? (
                                <>
                                    <p className="text-l">
                                        <Typografed>Форма успешно отправлена</Typografed>
                                    </p>
                                    {response.message && (
                                        <p
                                            className="text-s"
                                            dangerouslySetInnerHTML={{ __html: tp(response.message) }}
                                        ></p>
                                    )}
                                </>
                            ) : (
                                <>
                                    <p className="text-l">Произошла ошибка</p>
                                    {response.message && (
                                        <div
                                            className="text-s organize-form-error-message"
                                            dangerouslySetInnerHTML={{ __html: tp(response.message) }}
                                        ></div>
                                    )}
                                    <>
                                        <Button
                                            type="button"
                                            variant="primary-filled"
                                            onClick={() => {
                                                setResponse(null);
                                            }}
                                        >
                                            Отправить снова
                                        </Button>
                                        <a
                                            href={'https://t.me/TnfChatBot'}
                                            className="text-xs"
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                        >
                                            Служба поддержки TNF
                                        </a>
                                    </>
                                </>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>
            </form>
        </Popup>
    );
}

export default OrganizeFormPopup;
